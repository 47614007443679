<template>
  <div class="index">
    <div class="login">
        <div class="admin-title">全诚人力薪酬管理系统</div>
        <el-form label-width="100px" class="demo-ruleForm">
          <el-form-item label="手机号">
            <el-input v-model="phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" style="margin-top:40px">
            <el-input v-model="captchaCode" placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="verificationCode">
              <div class="btnCode" @click="getCheckCode">{{ disabled ? countDown+'秒后重新获取':countDown }}</div>
            </div>
          </el-form-item>
          <div class="submit">
            <el-button type="primary" @click="login">立即登录</el-button>
          </div>
        </el-form>
    </div>
  </div>
</template>

<script>
import { get, post } from '../../utils/request'
import { ElMessage } from 'element-plus'

export default {
  name: 'index',
  data () {
    return {
      phone: '', // 手机号
      captchaCode: '', // 验证码
      countDown: '获取验证码',
      disabled: false
    }
  },
  mounted () {},
  methods: {
    getCheckCode () { // 获取验证码倒计时
      if (!this.disabled) {
        this.disabled = true
        var num = 59
        this.countDown = 60
        this.getCode()
        var that = this
        var timer = setInterval(function () {
          that.countDown = num--
          if (that.countDown === 0) {
            that.countDown = '发送验证码'
            that.disabled = false
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    async getCode () { // 获取验证码方法
      try {
        const result = await get(`/api/v1/sms/captcha/${this.phone}`)
        console.log(result)
      } catch (e) {
        console.log(e)
      }
    },
    async login () {
      const result = await post('/api/v1/admin/info/login', { captchaCode: this.captchaCode, phone: this.phone })
      if (result.code === '0') { // 成功
        this.$store.dispatch('setSalaryTokenAction', result.data.tokenValue)
        location.reload()
      } else if (result.code === '101') {
        ElMessage({ type: 'info', message: '此手机号不是管理员' })
      } else {
        ElMessage({ type: 'info', message: result.msg })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
    background-color: #aad2dc;
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .login {
      width: 700px;
      height: 500px;
      background: #000000;
      opacity: 0.8;
      border-radius: 8px;
      .admin-title {
        font-size: 40px;
        text-align: center;
        padding: 70px 0 60px 0;
        color: #fff;
      }
      .demo-ruleForm {
        width: 80%;
        margin: 0 auto;
      }
      .type {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        div {
          margin: 0 10px;
          cursor: pointer;
        }
      }
      .verificationCode {
        display: flex;
        justify-content: flex-end;
        .btnCode {
          background: #dfe1e5;
          line-height: 25px;
          width: 140px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          -moz-user-select:none; /*火狐*/
          -webkit-user-select:none; /*webkit浏览器*/
          -ms-user-select:none; /*IE10*/
          -khtml-user-select:none; /*早期浏览器*/
          user-select:none;
        }
        .btnCode:hover {
          color:#ffffff;
          background-color: #6aa9f2;
        }
      }
      .submit {
        text-align: center;
        margin-top: 70px;
      }
    }
}
</style>
